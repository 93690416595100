import styled, { css } from 'styled-components';
import { MIN_CARD_WIDTH } from '@/components/AdCard/AdCard.constants';
import { SIDE_PADDING } from '@/components/AdCards/AdCards.constants';

export const Box = styled.div<{
  $customPaddingTop?: number;
  $customPaddingBottom?: number;
}>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${MIN_CARD_WIDTH}px, 1fr));
  justify-content: center;
  gap: 10px 9px;
  padding: ${SIDE_PADDING}px;

  ${({ $customPaddingTop }) =>
    $customPaddingTop &&
    css`
      padding-top: ${$customPaddingTop}px;
    `}
  ${({ $customPaddingBottom }) =>
    $customPaddingBottom &&
    css`
      padding-bottom: ${$customPaddingBottom}px;
    `}

  @media (max-width: 330px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const BannerBox = styled.div`
  grid-column: 1/-1;
  width: calc(100% + ${SIDE_PADDING * 2}px);
  margin-left: -${SIDE_PADDING}px;
`;
