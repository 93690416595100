import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';
import { AdCard } from '@/components/AdCard/AdCard';
import * as S from '@/components/AdCards/AdCards.styles';
import { generateRandomNumber, getDataStatAttr } from '@/utils/helpers';
import { Popup } from '@/components/UI/Popup/Popup';
import { useToggleOpenState } from '@/hooks/useToggleOpenState';
import { AdManagerBanner } from '@/components/Banners/AdManagerBanner';
import { BannerName } from '@/components/Banners/Banners.types';
import { getCardsCountInRow } from '@/components/AdCards/AdCards.helpers';
import {
  DEFAULT_ROWS_COUNT_BEFORE_BANNER,
  ROWS_COUNT_BEFORE_BANNER,
} from '@/components/AdCards/AdCards.constants';
import { Ad } from '@/api/types/common.types';
import { AdCardsProps } from '@/components/AdCards/AdCards.types';
import { CommonTestIds } from '@/__e2e__/testIds/common';
import { AdsChunkSize } from '@/components/AdsWithInfiniteScroll/AdsWithInfiniteScroll.types';
import { useQueryParam } from '@/hooks/useQueryParam';
import { QUERY } from '@/constants/common';

export const AdCards: React.FC<AdCardsProps> = ({
  ads,
  listDataStat,
  hasActions,
  hasFavorite,
  hasAdManagerCard,
  isInitialLoading,
  chunkSize = AdsChunkSize.Small,
  isLoadingMore,
  bannerInList,
  isBannerInListAtTheEnd,
  isBannerInListAfterThreeRows,
  customStyles,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isPopupOpen, popupToggle] = useToggleOpenState();
  const [selectedAdId, setSelectedAdId] = useState('');
  const [digitalSnail] = useQueryParam(QUERY.digitalSnail);

  function onTriggerRemove(id: Ad['id']) {
    setSelectedAdId(id);
    popupToggle();
  }

  function remove() {
    console.log('Ad to remove:', selectedAdId);
    setSelectedAdId('');
    popupToggle();
  }

  function isBannerRenders(idx: number): boolean {
    const cardsCountInRow = getCardsCountInRow(width);

    const rowsBeforeBanner =
      isBannerInListAtTheEnd && ads?.length
        ? ads.length / cardsCountInRow
        : DEFAULT_ROWS_COUNT_BEFORE_BANNER;

    const adjustedIndex = isBannerInListAtTheEnd ? idx + 1 : idx;

    const isBannerPosition =
      adjustedIndex % (rowsBeforeBanner * cardsCountInRow) === 0 ||
      (!!isBannerInListAfterThreeRows &&
        cardsCountInRow * ROWS_COUNT_BEFORE_BANNER === adjustedIndex);

    return idx !== 0 && isBannerPosition && !!bannerInList;
  }

  if (isInitialLoading) {
    return (
      <S.Box>
        {Array.from({ length: chunkSize }, (_, index) => (
          <AdCard key={index} isLoading />
        ))}
      </S.Box>
    );
  }

  if (!ads?.length) return null;

  const { paddingTop, paddingBottom } = customStyles || {};

  return (
    <>
      <S.Box
        $customPaddingTop={paddingTop}
        $customPaddingBottom={paddingBottom}
        {...getDataStatAttr({ dataStat: listDataStat })}
        data-testid={CommonTestIds.adCards.cardsBox}
      >
        {ads?.map((ad, idx) => {
          const key = `${ad.id}_${idx}`;
          const adCard = (
            <AdCard
              key={key}
              ad={ad}
              hasActions={hasActions}
              hasFavorite={hasFavorite}
              onTriggerRemove={onTriggerRemove}
            />
          );

          if (hasAdManagerCard && digitalSnail && idx === 3) {
            const banner = (
              <AdManagerBanner key={key} bannerName={BannerName.Card} />
            );
            return <Fragment key={`${key}_fragment`}>{banner}</Fragment>;
          }

          if (isBannerRenders(idx)) {
            const banner = (
              <S.BannerBox>
                {bannerInList?.(generateRandomNumber())}
              </S.BannerBox>
            );

            return (
              <Fragment key={`${key}_fragment`}>
                {isBannerInListAtTheEnd ? adCard : banner}
                {isBannerInListAtTheEnd ? banner : adCard}
              </Fragment>
            );
          }

          return adCard;
        })}

        {isLoadingMore && <AdCard isLoading />}
      </S.Box>

      {hasActions && (
        <Popup
          isOpen={isPopupOpen}
          close={popupToggle}
          title={t('pages.cabinet.remove_ad_question')}
          description={t('pages.cabinet.remove_ad_popup_text')}
          isInPortal
          actions={{
            left: { text: t('common.decline'), onClick: popupToggle },
            right: { text: t('common.remove'), isRed: true, onClick: remove },
          }}
        />
      )}
    </>
  );
};
