import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { GetUserBookmarksResponse } from '@/api/types/user.types';
import { GET_USER_BOOKMARKS } from '@/api/queries/user.queries';
import {
  CREATE_BOOKMARK,
  REMOVE_BOOKMARK,
} from '@/api/mutations/bookmarks.mutations';
import { BOOKMARKS_BATCH_SIZE } from '@/constants/common';
import { useBookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore';
import { ROUTES } from '@/constants/routes';
import {
  getStoredBookmarks,
  setStoredBookmarks,
  updateStoredBookmarks,
} from '@/store/useBookmarksStore/useBookmarksStore.helpers';
import { ToggleUserBookmark } from '@/hooks/bookmarks/useUserBookmarks.types';
import { GET_ADS_FOR_BOOKMARKS } from '@/api/queries/ads.queries';
import { sleep, sortByObjectProperty } from '@/utils/helpers';
import { GetAdsForBookmarksResponse } from '@/api/types/ads.types';
import { filterUnique } from '@/hooks/bookmarks/useUserBookmarks.helpers';
import { useAuthStore } from '@/store/useAuthStore';

let isFirstAuthHappened = false;

export function useUserBookmarks(isOnPage = true) {
  const [isAuthenticated] = useAuthStore(state => [state.isAuthenticated]);
  const router = useRouter();

  const [
    bookmarks,
    setBookmarks,
    endCursor,
    setEndCursor,
    setHasNextPage,
    totalCount,
    setTotalCount,
    bookmarkIds,
    setBookmarkIds,
    setLoadingState,
  ] = useBookmarksStore(state => [
    state.bookmarks,
    state.setBookmarks,
    state.endCursor,
    state.setEndCursor,
    state.setHasNextPage,
    state.totalCount,
    state.setTotalCount,
    state.bookmarkIds,
    state.setBookmarkIds,
    state.setLoadingState,
  ]);

  const { refetch: getBookmarks } = useQuery<GetUserBookmarksResponse>(
    GET_USER_BOOKMARKS,
    {
      skip: true,
      variables: { first: BOOKMARKS_BATCH_SIZE, after: endCursor },
    }
  );

  const { refetch: getAdsForBookmarks } = useQuery<GetAdsForBookmarksResponse>(
    GET_ADS_FOR_BOOKMARKS,
    { skip: true }
  );

  const [createBookmark] = useMutation(CREATE_BOOKMARK, {
    refetchQueries: [GET_USER_BOOKMARKS],
  });

  const [removeBookmark] = useMutation(REMOVE_BOOKMARK, {
    refetchQueries: [GET_USER_BOOKMARKS],
  });

  const toggleUserBookmark: ToggleUserBookmark = async ad => {
    if (!ad) return;

    const isBookmarked = bookmarkIds.has(ad.id);

    if (isBookmarked) {
      bookmarkIds.delete(ad.id);
      const updated = bookmarks.filter(b => b.id !== ad.id);
      setBookmarks(updated);
      setStoredBookmarks(updated);

      if (isAuthenticated) {
        await removeBookmark({ variables: { adId: ad.id } });
      }
    } else {
      bookmarkIds.add(ad.id);
      const updated = [{ ...ad, order: Date.now() }, ...bookmarks];
      setBookmarks(updated);
      setStoredBookmarks(updated);

      if (isAuthenticated) {
        await createBookmark({ variables: { adIds: [ad.id] } });
      }
    }
  };

  async function fetchBookmarksHelper(
    first = BOOKMARKS_BATCH_SIZE,
    after = endCursor
  ) {
    const { data } = await getBookmarks({ first, after });

    const {
      pageInfo,
      nodes,
      totalCount: totalCountFromResponse,
    } = data?.currentUser?.bookmarks || {};

    if (!totalCount && totalCountFromResponse) {
      setTotalCount(totalCountFromResponse);
    }

    if (pageInfo) {
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);
    }

    if (nodes) {
      setBookmarkIds(nodes);
      const storedBookmarks = getStoredBookmarks();
      const lastStoredOrder =
        storedBookmarks[storedBookmarks.length - 1]?.order || Date.now();
      const bookmarksWithOrder = nodes.map((n, i) => ({
        ...n,
        order: lastStoredOrder - (i + 1) * 1_000,
      }));
      const uniques = filterUnique([...bookmarksWithOrder, ...storedBookmarks]);
      const sorted = sortByObjectProperty(uniques, 'order');
      setBookmarks(sorted);
      setStoredBookmarks(sorted);
    }

    return {
      totalCountFromResponse,
      cursor: pageInfo?.endCursor,
      hasNext: pageInfo?.hasNextPage,
    };
  }

  async function fetchWithCondition(
    count?: number,
    cursor?: string,
    condition?: boolean
  ) {
    const storedBookmarks = getStoredBookmarks();
    if (
      count &&
      count > storedBookmarks.length &&
      router.pathname !== ROUTES.bookmarks &&
      condition
    ) {
      await fetchBookmarksHelper(count - storedBookmarks.length, cursor);
    }
  }

  async function fetchBookmarks() {
    if (!isAuthenticated) return;

    if (bookmarks.length) {
      setLoadingState('loadingMore');
    }

    await fetchWithCondition(totalCount, endCursor, !!endCursor);

    const { totalCountFromResponse, cursor, hasNext } =
      await fetchBookmarksHelper();

    await fetchWithCondition(totalCountFromResponse, cursor, hasNext);

    setLoadingState('idle');
  }

  function clearAll() {
    if (!isAuthenticated) return;

    setBookmarks([]);
    setBookmarkIds([]);
    setStoredBookmarks([]);
    setEndCursor('');
    setHasNextPage(true);
    setTotalCount(0);
  }

  useEffect(() => {
    if (isOnPage) {
      clearAll();
    }

    router.events.on('routeChangeStart', clearAll);

    return () => {
      router.events.off('routeChangeStart', clearAll);
    };
  }, [isAuthenticated]);

  async function processIdsFromOldUI() {
    if (isAuthenticated && isFirstAuthHappened) return;

    if (isAuthenticated) {
      isFirstAuthHappened = true;
    }

    const storedBookmarks = getStoredBookmarks();
    const storedBookmarksIds = storedBookmarks.reduce<string[]>((a, c) => {
      if (c.order && !c.title) {
        a.push(c.id);
      }
      return a;
    }, []);

    if (!storedBookmarksIds) return;

    try {
      if (!storedBookmarksIds.length) return;
      const { data } = await getAdsForBookmarks({ ids: storedBookmarksIds });

      const bookmarksWithOrder = data.ads.nodes.map((ad, i) => {
        const order =
          storedBookmarks.find(b => b.id === ad.id)?.order ||
          Date.now() - (i + 1) * 1_000;
        return { ...ad, order };
      });

      updateStoredBookmarks(bookmarksWithOrder);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (router.query.hasLangChanged) return;

    (async () => {
      await processIdsFromOldUI();
      const storedBookmarks = getStoredBookmarks();
      setBookmarks(storedBookmarks);
    })();
  }, [router.asPath]);

  useEffect(() => {
    (async () => {
      if (isOnPage) {
        // TODO remove sleep after /messages and /profile will be on react
        await sleep(1000);
        !bookmarks.length && setLoadingState('initialLoading');
        await fetchBookmarks();
        setLoadingState('idle');
      }
    })();
  }, [isAuthenticated, router.asPath]);

  useEffect(() => {
    setBookmarkIds(bookmarks);
  }, [bookmarks.length]);

  return { toggleUserBookmark, fetchBookmarks };
}
