export const PAGE_WIDTH = {
  XXL: 1092,
  XL: 939,
  LG: 786,
  MD: 633,
  SM: 480,
  XS: 320,
};

export const DEFAULT_ROWS_COUNT_BEFORE_BANNER = 15;

export const ROWS_COUNT_BEFORE_BANNER = 3;

export const SIDE_PADDING = 15;
