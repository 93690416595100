import styled from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { Divider } from '@/components/UI/Divider/Divider.styles';

export const FooterBox = styled.footer<{ $bottomGutter: string }>`
  padding: ${({ theme, $bottomGutter }) => {
    if (theme.isWebView) {
      return `25px 15px calc(env(safe-area-inset-bottom) + 25px + ${$bottomGutter})`;
    }

    return `25px 15px ${$bottomGutter}`;
  }};
  margin: 0 auto;
  background-color: ${({ theme }) =>
    theme.isWebView ? 'transparent' : theme.palette.primary.background};
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  > a {
    max-width: max-content;
  }
`;

export const FooterSocials = styled.div`
  display: flex;
  gap: 25px;
  margin: 25px 0;
`;

export const FooterSocial = styled.a<{ $iconName: string }>`
  display: block;
  background-image: ${({ $iconName }) => `url('/images/${$iconName}.svg'`});
  height: 20px;
  width: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const FooterSiteVersions = styled.div`
  display: flex;
  align-items: baseline;
  gap: 15px;
`;

export const FooterPhones = styled.div`
  margin-bottom: 10px;
`;

export const FooterResponsibility = styled(Typography.Caption)`
  max-width: 85%;
  margin: 15px 0;
`;

export const FooterDivider = styled(Divider)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.palette.secondary.border};
`;

export const PhoneNumber = styled(Typography.Numbers)`
  color: ${({ theme }) => theme.palette.secondary.labels};
`;

export const MailAddress = styled(Typography.Body)`
  color: ${({ theme }) => theme.palette.secondary.labels};
`;

export const FooterChangeLang = styled.div`
  position: relative;
  display: flex;
  gap: 10px;

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-image: url('/images/language.svg');
    background-color: ${({ theme }) => theme.palette.secondary.labels};
  }
`;
