import React from 'react';
import { AdKind } from '@/api/types/common.types';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import * as S from '@/components/KindIcons/KindIcons.styles';
import { KIND_ICON_NAMES } from '@/components/KindIcons/KindIcons.constants';

type Props = {
  kinds?: AdKind[];
  isInCard?: boolean;
};

export const KindIcons = ({ kinds, isInCard }: Props) => {
  return (
    <S.Box $isInCard={isInCard}>
      {kinds?.map(kind => {
        const icon = KIND_ICON_NAMES[kind];

        if (!icon) return null;

        return (
          <IconBox
            key={kind}
            $filename={`${icon}.svg`}
            $iconWidth={isInCard ? 16 : 15}
            $iconHeight={13}
            data-testid="ServiceIcon"
          />
        );
      })}
    </S.Box>
  );
};
