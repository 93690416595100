import { Ad } from '@/api/types/common.types';
import { AdDetails } from '@/api/types/ads.types';

export function buildBookmarkForLS(ad?: Ad | AdDetails | null): Ad | null {
  if (!ad) return null;

  const photoUrl = ad.photo?.url ?? ('photos' in ad ? ad.photos?.[0]?.url : '');

  return {
    id: ad.id,
    title: ad.title,
    price: ad.price,
    updatedAt: ad.updatedAt,
    region: ad.region,
    path: ad.path,
    kinds: ad.kinds,
    shop: ad.shop,
    photo: { url: photoUrl },
    legacyResourceId: ad.legacyResourceId,
  };
}
