import { useEffect, useState } from 'react';
import { Ad } from '@/api/types/common.types';
import { AdDetails } from '@/api/types/ads.types';
import { useBookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore';
import { useUserBookmarks } from '@/hooks/bookmarks/useUserBookmarks';
import { buildBookmarkForLS } from '@/utils/bookmarks';
import { ToggleBookmark } from '@/hooks/bookmarks/useToggleBookmark.types';

export const useToggleBookmark = ({
  ad,
  onSuccess,
  isOnPage = false,
}: {
  ad?: Ad | AdDetails | null;
  onSuccess?: () => void;
  isOnPage?: boolean;
}) => {
  const bookmarkIds = useBookmarksStore(state => state.bookmarkIds);

  const { toggleUserBookmark } = useUserBookmarks(isOnPage);

  const [isFavorite, setIsFavorite] = useState(false);

  const toggleBookmark: ToggleBookmark = async e => {
    e.stopPropagation();
    e.preventDefault();

    if (!ad) return;

    setIsFavorite(prev => !prev);

    try {
      await toggleUserBookmark?.(buildBookmarkForLS(ad));
      onSuccess?.();
    } catch (error) {
      setIsFavorite(prev => !prev);
    }
  };

  useEffect(() => {
    if (!ad) return;

    setIsFavorite(!!bookmarkIds?.has(ad.id));
  }, [bookmarkIds?.size, ad]);

  return { isFavorite, toggleBookmark };
};
