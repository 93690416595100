import styled, { css } from 'styled-components';
import { ButtonBox } from '@/components/UI/Button/Button.styles';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import {
  ACTIONS_HEIGHT,
  POPUP_SIZES,
} from '@/components/UI/Popup/Popup.constants';
import { MODAL_ANIMATION_DURATION } from '@/styles/constants';
import { PopupSize } from '@/components/UI/Popup/Popup.types';

export const PopupBox = styled.div<{
  $isOpen?: boolean;
  $size: PopupSize;
}>`
  max-height: 95dvh;
  height: max-content;
  overflow: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ $size }) => POPUP_SIZES[$size]};
  z-index: 10200;
  transition: ${MODAL_ANIMATION_DURATION}ms ease-in-out;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};

  .popup__icon {
    margin: 20px auto -10px;
  }
`;

export const PopupTitle = styled(Typography.Header).attrs(() => ({
  $variant: '3',
}))<{
  $capitalizeTitle?: boolean;
}>`
  text-align: center;
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 10px;

  ${({ $capitalizeTitle }) =>
    $capitalizeTitle &&
    css`
      text-transform: capitalize;
    `}
`;

export const PopupDescription = styled(Typography.Title)`
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.labels};
  padding: 0 20px 20px;
  margin-top: 10px;
`;

export const PopupDescriptionBox = styled.div`
  display: grid;
  gap: 15px;
  overflow-y: auto;
  padding: 0 20px 20px;
  margin-top: 10px;
`;

export const PopupActions = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.palette.primary.border};
  height: ${ACTIONS_HEIGHT}px;
`;

export const PopupAction = styled(ButtonBox)<{
  $isRed?: boolean;
  $isGray?: boolean;
}>`
  width: 100%;
  border-radius: 0;
  color: ${({ theme, $isRed, $isGray }) =>
    $isRed
      ? theme.palette.system.red
      : $isGray
      ? theme.palette.secondary.labels
      : theme.palette.system.blue};
  font-size: 15px;

  &:nth-child(2) {
    border-left: 1px solid ${({ theme }) => theme.palette.primary.border};
  }
`;
